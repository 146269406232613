



















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { userPersonPage } from '@/api/system/PersonApi';
import { getCompanyTreeData, getUserCompany } from '@/api/system/OrganizationApi';
import UserUtil from '@/utils/UserUtil';
import PersonTransferForm from './person-transfer-form.vue';
@Component({
  name: 'PersonTransferList',
  components: { PersonTransferForm }
})
export default class PersonTransferList extends Vue {
  // 表单搜索
  formSearchModel: any = { name: '' ,deptId:null};
  // 表格加载动画
  loadingOuter: boolean = false;
  // 表格数据
  formListData: any[] = [];
  // 分页参数
  pageIndex: number = 1;
  // 每页大小
  pageSize: number = 10;
  // 总条数
  total: number = 0;
  // 当前页
  currentPage: number = 1;
  //   是否显示调动弹框
  isShowMove: boolean = false;
  //   当前选中的数据
  currtent: any = null;
  // 公司树列表
  companyData: any[] = [];
  // 当前的用户的公司id
  userCompanyId: string = '';
  // 选中的公司数据
  selectCompanyData: any = {};
   //选中公司的部门
  depts: any = [];
  // 当前选中的左侧树节点
  currentNodeKey: string = '';
  // 当前选中树的公司名称
  currentNodeName: string = '';

  mounted() {
    this.getParentDeptGroupSelectList();
  }

  @Watch('currentNodeKey', { deep: true })
  currentNodeKeyWatcher(id) {
    if (id.toString()) {
      (this.$refs['appTreeRef'] as any).setCurrentKey(id);
    } else {
      (this.$refs['appTreeRef'] as any).setCurrentKey(null);
    }
  }

  /**
   * 获取当前登录人的公司信息
   */
  async getUserCompany() {
    this.userCompanyId = '';
    let params = {
      tenantId: UserUtil.getUser().tenantId,
      userId: UserUtil.getUser().id
    };
    let { data } = await getUserCompany(params);
    if (data.length > 0) {
      this.currentNodeKey = data[0].id;
      this.userCompanyId = data[0].id;
      this.currentNodeName = this.getCompanyName(this.companyData, data[0].id);
      this.getdeptList(this.userCompanyId);
    }
    this.getPersonPage();
  }
  /**
   * 获取人员分页数据
   */
  getPersonPage() {
    let params = {
      name: this.formSearchModel.name,
      deptId:this.formSearchModel.deptId,
      companyId: this.userCompanyId,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.loadingOuter = true;
    userPersonPage(params)
      .then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.formListData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        this.loadingOuter = false;
      });
  }

  // 获取上级机构组下拉列表
  getParentDeptGroupSelectList() {
    getCompanyTreeData({ type: '0' })
      .then((res: Record<any, any>) => {
        this.companyData = res.data;
        this.getUserCompany();
      })
      .finally(() => {});
  }

  /**
   * 获取公司名称
   */
  getCompanyName(list, id) {
    for (let i = 0; i < list.length; i++) {
      let a = list[i];
      if (a.id === id) {
        return a.name;
      } else {
        if (a.children && a.children.length > 0) {
          let res = this.getCompanyName(a.children, id);
          if (res) {
            return res;
          }
        }
      }
    }
  }

  /**
   * 应用树节点点击事件
   * @param data
   */
  clickTreeNode(data) {
    this.userCompanyId = '';
    this.selectCompanyData = { ...data };
    if (data.id) {
      this.userCompanyId = data.id;
      this.currentNodeName = data.name;
      this.pageIndex = 1;
      this.formSearchModel.deptId = null;
      this.getPersonPage();
      this.getdeptList(data.id);
    }
  }
// 部门列表
  getdeptList(parentId:string) {
    getCompanyTreeData({parentId:parentId, type: '1',cascadeType:'2'})
      .then((res: Record<any, any>) => {
        this.depts = res.data;
      })
      .finally(() => {});
  }

    treeNormalizer(node) {
    let params = {
      id: node.id,
      label: node.name,
      children: node.children,
      isDisabled: false
    };
    if (node.type == '0') {
      params.isDisabled = true;
    }
    return params;
  }
  /**
   * 点击搜索
   */
  handleSearch() {
    this.pageIndex = 1;
    this.getPersonPage();
  }

  /**
   * 重置
   */
  handleSearchReset() {
    // 置空搜索表单
    this.pageIndex = 1;
    this.formSearchModel = { name: '' };
    this.getPersonPage();
  }

  /**
   * 点击调动
   */
  movePerson(row: any) {
    this.currtent = row;
    this.isShowMove = true;
  }

  /**
   * 改变分页大小
   */
  changePageSize(pageSize: number) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getPersonPage();
  }

  /**
   * 跳转至某页
   */
  changePage(page: number) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getPersonPage();
  }
}
