



































































































































































































































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import DeptAddEdit from '@/views/organization/person/dept-add-edit.vue';
import { getCompanyTreeData, getCompanys } from '@/api/system/OrganizationApi';
import {
  accountExists,
  userTypes,
  personDetails,
  addOrEditPerson
} from '@/api/system/PersonApi';
@Component({
  name: 'PersonTransferForm',
  components: { DeptAddEdit }
})
export default class PersonTransferForm extends Vue {
  // ref
  @Ref() readonly formRef;
  @Prop({ default: false }) private visible!: boolean;
  //   当前数据
  @Prop({ default: undefined }) currtent;
  // 调出公司id
  @Prop({ default: '' }) userCompanyId!: string;
  // 调出公司名称
  @Prop({ default: '' }) currentNodeName!: string;
  //   表单加载load
  formLoading: boolean = false;
  //   表单数据
  personData: any = {
    companyId: null,
    // 调入公司信息
    transferInData: [],
    // 调出公司信息
    takeOffices: []
  };
  // 可以选择的公司列表
  companyList: any[] = [];
  //   默认公司列表
  defalutCompanyList: any[] = [];
  // 老的默认公司列表
  oldDefalutCompanyList: any[] = [];
  // 用户类型列表
  userTypeList: any[] = [];
  //   当前信息
  deptRow: any = null;
  // 部门编辑时用于编辑
  deptIndex: number = null;
  //   弹框的部门列表
  deptListData: any[] = [];
  //   弹框的职务列表
  dutyListData: any[] = [];
  //   调入可以选择部门信息
  deptInListData: any[] = [];
  //   调入可以选择的职务信息
  dutyInListData: any[] = [];
  //   调出的部门列表
  deptOutListData: any[] = [];
  //   调出的职务列表
  dutyOutListData: any[] = [];
  addFlag: boolean = false;
  //   弹框的公司id
  diaoLogCompanyId: string = '';
  //   是否显示部门信息弹框
  isShowAddEdit: boolean = false;
  //   账号结尾
  accountSuffix: string = '';
  //   验证规则
  formRules: Record<string, any> = {
    companyId: {
      required: true,
      message: this.$t('lang_please_select_company_name'),
      trigger: 'change'
    },
    account: {
      required: true,
      message: this.$t('lang_please_entry_a_account'),
      trigger: 'blur'
    },
    sort: {
      required: true,
      message: this.$t('lang_form_please_enter_a_sort'),
      trigger: 'blur'
    },
    userType: {
      required: true,
      message: this.$t('lang_please_select_user_type'),
      trigger: 'change'
    },
    outType: {
      required: true,
      message: this.$t('lang_please_select_transfer_out_company_processing_method'),
      trigger: 'change'
    }
  };
  laborContract: any = {};

  created() {
    this.getParentDeptGroupSelectList();
    this.getUserTypesList();
    this.getDeptGroupSelectList(this.userCompanyId, 'out');
    this.getDutySelectList(this.userCompanyId, 'out');
    if (this.currtent) {
      this.getPersonDetail();
    }
  }

  /**
   * 获取详情
   */
  getPersonDetail() {
    let params = {
      companyId: this.userCompanyId,
      personId: this.currtent.personId,
      idCard: this.currtent.idCard || ''
    };
    personDetails(params).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.$nextTick(() => {
          this.personData = res.data;
          this.laborContract = res.data?.laborContract;
          this.getCompanyList(res.data.user.id);
          // 用户排序
          this.personData.sort = res.data.user.sort;
          // 用户类型
          if (res.data.user.userType) {
            this.personData.userType = res.data.user.userType;
          }
          // 账号
          let aco = res.data.user.account.split('@');
          this.$set(this.personData, 'account', aco[0]);
        });
      }
    });
  }

  /**
   * 编辑时获取主公司列表
   */
  getCompanyList(userId) {
    getCompanys({ userId: userId }).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.defalutCompanyList = res.data;
        this.oldDefalutCompanyList = res.data;
        // this.personData.defaultCompanyId = this.getCompanyId(res.data);
      }
    });
  }

  /**
   * 获取主公司id
   */
  getCompanyId(list) {
    for (let i = 0; i < list.length; i++) {
      let a = list[i];
      if (a.defaultAble) {
        return a.id;
      } else {
        if (a.children && a.children.length > 0) {
          let res = this.getCompanyId(a.children);
          if (res) {
            return res;
          }
        }
      }
    }
  }

  /**
   * 保存
   */
  save() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      if (this.personData.transferInData.length == 0) {
        return this.$message.warning(
          this.$t('lang_please_enter_an_employment_information') as any
        );
      }

      if (this.personData.takeOffices.length == 0) {
        return this.$message.warning(
          this.$t(
            'Please enter an employment information of the transfer out company'
          ) as any
        );
      }

      /**
       * 判断调入调出公司的主管部门
       */
      let inFlag = false;
      let outFlag = false;
      this.personData.transferInData.forEach((item) => {
        if (item.defaultAble) return (inFlag = true);
      });
      this.personData.takeOffices.forEach((item) => {
        if (item.defaultAble) return (outFlag = true);
      });

      /**
       * 调入调出必须有一个主管部门
       */
      if (!inFlag)
        return this.$message.error(
          this.$t('lang_the_transfer_in_company_must_have_a_competent_department') as any
        );
      if (!outFlag)
        return this.$message.error(
          this.$t('lang_the_transfer_out_company_must_have_a_competent_department') as any
        );
      /**
       * 如果是离职，调出的数据全改为离职状态
       */
      if (this.personData.outType == '2') {
        this.personData.takeOffices.forEach((item) => {
          item.status = '2';
        });
      }

      /**
       * 默认主公司,如果当前的公司为主公司并且选择的是离职
       */

      if (this.currtent.mainCompany && this.personData.outType == '2') {
        this.personData.defaultCompanyId = this.personData.companyId;
      }

      /**
       * 为人员信息赋值
       */
      this.personData.user.account = this.personData.account + this.accountSuffix;
      this.personData.user.sort = this.personData.sort;
      this.personData.user.userType = this.personData.userType;

      /**
       * 拼接总的takeOffices数据
       */

      this.personData.transferInData.forEach((item) => {
        this.personData.takeOffices.push(item);
      });

      // 删除多余数据
      delete this.personData.transferInData;

      addOrEditPerson(this.personData)
        .then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // 关闭弹窗
            this.handleCloseDialog();
            this.$emit('success');
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          // 关闭加载框
        });
    });
  }

  /**
   * 账号验证
   */
  accountChange(val) {
    accountExists({ account: this.personData.account + this.accountSuffix }).then(
      (res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.$message.error(
            this.$t('lang_account_number_already_exists_please_re-enter') as any
          );
        }
      }
    );
  }

  /**
   * 获取用户类型列表
   */
  getUserTypesList() {
    userTypes().then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.userTypeList = res.data;
      }
    });
  }

  /**
   * 点击新增部门
   */
  deptAddClick(row, index, flag) {
    this.deptRow = row;
    if (row) {
      this.deptIndex = index;
    }
    // 调入
    if (flag == 'in') {
      if (!this.personData.companyId)
        return this.$message.warning(
          this.$t('lang_please_select_the_transfer_in_company_first') as any
        );
      this.diaoLogCompanyId = this.personData.companyId;
      this.deptListData = this.deptInListData;
      this.dutyListData = this.dutyInListData;
      this.addFlag = true;
    } else {
      this.diaoLogCompanyId = this.userCompanyId;
      this.deptListData = this.deptOutListData;
      this.dutyListData = this.dutyOutListData;
      this.addFlag = false;
    }
    this.isShowAddEdit = true;
  }

  /**
   * 公司名称改变
   */
  companyNameChange(val) {
    if (val) {
      let params = {
        companyId: val.id,
        personId: this.currtent.personId,
        idCard: this.currtent.idCard || ''
      };
      personDetails(params).then((res: Record<any, any>) => {
        if (res.code == '1') {
          if (res.data.takeOffices && res.data.takeOffices.length > 0) {
            this.$message.error(
              this.$t('lang_the_current_user_already_exists_in_the_company') as any
            );
            this.personData.companyId = null;
            return;
          } else {
            this.personData.user.tenantId = val.tenantId;
            this.accountSuffix = '@' + val.groupCode;
            this.personData.transferInData = [];
            this.getDeptGroupSelectList(val.id, 'in');
            this.getDutySelectList(val.id, 'in');
            // 吧选中的公司加入主公司列表
            let arr = [...this.oldDefalutCompanyList];
            arr.push(val);
            this.defalutCompanyList = arr;
          }
        }
      });
    }
  }

  /**
   * 部门信息弹框关闭
   */
  deptClose(data) {
    /**
     * 编辑
     */
    if (this.deptRow) {
      /**
       * 调入
       */
      if (this.addFlag) {
        setDefaultAble(data, this.personData.transferInData);
        this.personData.transferInData.splice(this.deptIndex, 1, data);
      } else {
        /**
         * 调出
         */
        setDefaultAble(data, this.personData.takeOffices);
        this.personData.takeOffices.splice(this.deptIndex, 1, data);
      }
    } else {
      /**
       * 新增
       */
      if (this.addFlag) {
        /**
         * 调入
         */
        setDefaultAble(data, this.personData.transferInData);
        this.personData.transferInData.push(data);
      } else {
        /**
         * 调出
         */
        setDefaultAble(data, this.personData.takeOffices);
        this.personData.takeOffices.push(data);
      }
    }

    /**
     * 设置是否是主管部门
     */
    function setDefaultAble(data, name) {
      if (data.defaultAble) {
        name.forEach((item) => {
          item.defaultAble = false;
        });
      }
    }
  }

  /**
   * 获取部门职务名称
   */
  getDeptName(list, id) {
    for (let i = 0; i < list.length; i++) {
      let a = list[i];
      if (a.id === id) {
        return a.name;
      } else {
        if (a.children && a.children.length > 0) {
          let res = this.getDeptName(a.children, id);
          if (res) {
            return res;
          }
        }
      }
    }
  }

  /**
   * 部门删除
   */
  deptInRemove(index) {
    this.personData.transferInData.splice(index, 1);
  }

  // 获取调入公司下拉列表
  getParentDeptGroupSelectList() {
    getCompanyTreeData({ tenantIgnore: true, type: '0' })
      .then((res: Record<any, any>) => {
        this.companyList = res.data;
      })
      .finally(() => {});
  }

  // 获取部门下拉列表
  getDeptGroupSelectList(companyId, flag) {
    getCompanyTreeData({
      parentId: companyId,
      types: ['0', '1'],
      cascadeType: '2',
      tenantIgnore: flag == 'in' ? true : false
    })
      .then((res: Record<any, any>) => {
        if (flag == 'in') {
          this.deptInListData = res.data;
        } else {
          this.deptOutListData = res.data;
        }
      })
      .finally(() => {});
  }

  // 获取职务下拉列表
  getDutySelectList(companyId, flag) {
    getCompanyTreeData({
      parentId: companyId,
      types: ['2'],
      cascadeType: '2',
      tenantIgnore: flag == 'in' ? true : false
    })
      .then((res: Record<any, any>) => {
        if (flag == 'in') {
          this.dutyInListData = res.data;
        } else {
          this.dutyOutListData = res.data;
        }
      })
      .finally(() => {});
  }

  menuTreeNormalizer(node) {
    let params = {
      id: node.id,
      label: node.name,
      children: node.children
    };
    return params;
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
