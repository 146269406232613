var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title: _vm.$t("lang_personnel_transfer"),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "formRef",
          attrs: {
            model: _vm.personData,
            "label-suffix": "：",
            rules: _vm.formRules,
            "label-width": "auto",
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c("div", { staticClass: "text_box" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("lang_transfer_in_company_information"))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text_box_line" }),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_company_name"),
                        prop: "companyId",
                      },
                    },
                    [
                      _c("treeselect", {
                        attrs: {
                          options: _vm.companyList,
                          "append-to-body": "",
                          "z-index": 2999,
                          "default-expand-level": 1,
                          normalizer: _vm.menuTreeNormalizer,
                          placeholder: _vm.$t("lang_please_select"),
                        },
                        on: { select: _vm.companyNameChange },
                        model: {
                          value: _vm.personData.companyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.personData, "companyId", $$v)
                          },
                          expression: "personData.companyId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "text_box" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "lang_employment_information_of_transfer_in_company"
                      )
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text_box_line" }),
              _c(
                "el-row",
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.deptAddClick(
                                undefined,
                                undefined,
                                "in"
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_new")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.personData.transferInData,
                        border: "",
                        "empty-text": _vm.$t("lang_no_data"),
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_department"),
                          align: "center",
                          prop: "deptId",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDeptName(
                                        _vm.deptInListData,
                                        scope.row.deptId
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_duty"),
                          align: "center",
                          prop: "dutyId",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDeptName(
                                        _vm.dutyInListData,
                                        scope.row.dutyId
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_is_it_the_competent_department"),
                          align: "center",
                          prop: "defaultAble",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.defaultAble
                                        ? _vm.$t("lang_yes")
                                        : _vm.$t("lang_no")
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_tenure"),
                          align: "center",
                          prop: "takeOfficeTime",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_employment_status"),
                          align: "center",
                          prop: "status",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.status == "1"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v(_vm._s(_vm.$t("lang_in_office")))]
                                    )
                                  : _vm._e(),
                                scope.row.status == "2"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("lang_leave_office"))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                scope.row.status == "3"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v(_vm._s(_vm.$t("lang_delete")))]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_reason_for_employment"),
                          align: "center",
                          prop: "takeOfficeReason",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_operation"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      color: "#007aff",
                                      "margin-left": "10px",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deptAddClick(
                                          scope.row,
                                          scope.$index,
                                          "in"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("lang_edit")))]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      color: "#e03e2d",
                                      "margin-left": "10px",
                                    },
                                    attrs: { slot: "reference", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deptInRemove(scope.$index)
                                      },
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("lang_delete")))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "text_box" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("lang_transfer_in_company_account_information")
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text_box_line" }),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("lang_account"), prop: "account" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: _vm.$t("lang_please_enter") },
                          on: { change: _vm.accountChange },
                          model: {
                            value: _vm.personData.account,
                            callback: function ($$v) {
                              _vm.$set(_vm.personData, "account", $$v)
                            },
                            expression: "personData.account",
                          },
                        },
                        [
                          _vm.accountSuffix
                            ? _c("template", { slot: "append" }, [
                                _vm._v(_vm._s(_vm.accountSuffix) + " "),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("lang_sort"), prop: "sort" } },
                    [
                      _c("el-input-number", {
                        model: {
                          value: _vm.personData.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.personData, "sort", $$v)
                          },
                          expression: "personData.sort",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_user_type"),
                        prop: "userType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("lang_please_select_user_type"),
                            clearable: "",
                          },
                          model: {
                            value: _vm.personData.userType,
                            callback: function ($$v) {
                              _vm.$set(_vm.personData, "userType", $$v)
                            },
                            expression: "personData.userType",
                          },
                        },
                        _vm._l(
                          _vm.userTypeList,
                          function (userItem, userIndex) {
                            return _c("el-option", {
                              key: userIndex,
                              attrs: {
                                label: userItem.name,
                                value: userItem.code,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "text_box" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("lang_call_out")) +
                      _vm._s(_vm.currentNodeName) +
                      _vm._s(_vm.$t("lang_information"))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text_box_line" }),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          "lang_transfer_out_company_processing_method"
                        ),
                        prop: "outType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t(
                              "lang_please_select_transfer_out_company_processing_method"
                            ),
                            clearable: "",
                          },
                          model: {
                            value: _vm.personData.outType,
                            callback: function ($$v) {
                              _vm.$set(_vm.personData, "outType", $$v)
                            },
                            expression: "personData.outType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t(
                                "lang_retain_all_positions_of_the_transfer_out_company"
                              ),
                              value: "1",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t(
                                "lang_quit_all_jobs_of_the_transfer_out_company"
                              ),
                              value: "2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "text_box" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("lang_call_out")) +
                      _vm._s(_vm.currentNodeName) +
                      _vm._s(_vm.$t("lang_employment_information"))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text_box_line" }),
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.personData.takeOffices,
                        border: "",
                        "empty-text": _vm.$t("lang_no_data"),
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_department"),
                          align: "center",
                          prop: "deptId",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDeptName(
                                        _vm.deptOutListData,
                                        scope.row.deptId
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_duty"),
                          align: "center",
                          prop: "dutyId",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDeptName(
                                        _vm.dutyOutListData,
                                        scope.row.dutyId
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_is_it_the_competent_department"),
                          align: "center",
                          prop: "defaultAble",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.defaultAble
                                        ? _vm.$t("lang_yes")
                                        : _vm.$t("lang_no")
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_tenure"),
                          align: "center",
                          prop: "takeOfficeTime",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_employment_status"),
                          align: "center",
                          prop: "status",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.status == "1"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v(_vm._s(_vm.$t("lang_in_office")))]
                                    )
                                  : _vm._e(),
                                scope.row.status == "2"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("lang_leave_office"))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                scope.row.status == "3"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v(_vm._s(_vm.$t("lang_delete")))]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_reason_for_employment"),
                          align: "center",
                          prop: "takeOfficeReason",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_time_of_resignation"),
                          align: "center",
                          prop: "leaveOfficeTime",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_reason_for_resignation"),
                          align: "center",
                          prop: "leaveOfficeReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
      _vm.isShowAddEdit
        ? _c("dept-add-edit", {
            attrs: {
              visible: _vm.isShowAddEdit,
              deptRow: _vm.deptRow,
              addFlag: _vm.addFlag,
              deptListData: _vm.deptListData,
              dutyListData: _vm.dutyListData,
              companyId: _vm.diaoLogCompanyId,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isShowAddEdit = $event
              },
              done: _vm.deptClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }